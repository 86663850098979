import React from 'react';

function DotPulse() {
    return (
        <div className="dot-pulse">
            <div className="bounce"></div>
            <div className="bounce"></div>
            <div className="bounce"></div>
        </div>
    );
}

export default DotPulse;
